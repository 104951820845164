import React, { useState } from 'react'
import styled from 'styled-components'
// import { ReUseHTwotag } from './ReUseHTwoTag'

const PartnersCarouselSliderWrapper = styled.div`
  min-height: 40vh;
  display: grid;
  place-items: center;

  .partner-Slider {
    height: auto;
    margin: auto;
    position: relative;
    width: 90%;
    display: flex;
    place-items: center;
    overflow: hidden;
  }

  .partner-Slide-loop-Track {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* width: calc(250px * 28);
    animation: scroll 60s linear infinite; */
    margin-bottom: 130px;
  }

  /* .partner-Slide-loop-Track:hover {
    animation-play-state: paused;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 19));
    }
  } */

  .partner-Slide {
    height: 60px;
    width: 154px;
    margin: 4px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    align-items: center;
    padding: 20px;
    perspective: 100px;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 1s;
    object-fit: cover;
  }

  img:hover {
    transform: translateZ(20px);
  }
  a {
    width: 100%;
    transition: transform 1s;
  }

  a:hover {
    transform: translateZ(20px);
  }

  /* .partner-Slider::before {
    left: 0;
    top: 0;
  }

  .partner-Slider::before {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  } */
  .PartnersCompanyNewsSecondSection {
    margin-top: 60px;
  }
`

function AppPartnersCarousalSlider() {
  const [] = useState(new Date())

  return (
    <PartnersCarouselSliderWrapper>
      {/* <div className="PartnersCompanyNewsSecondSection">
        <ReUseHTwotag Heading="Raindrop is Trusted by our Industry Partners" HeadingStyle="LatestNewsHeading" />
      </div> */}
      <div className="partner-Slider">
        <div className="partner-Slide-loop-Track">
          <div className="partner-Slide">
            <a href="https://velocityprocurement.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Velocity-logo.png"
                alt="Velocity-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.beltreesconsultingservices.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/beltrees_brandmark%2Blogo.png"
                alt="beltrees-consulting-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.crescendo-global.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Crescendo.png"
                alt="Crescendo-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://consus.ycp.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/HB-YCP-CONSUS.png"
                alt="ycp-consus-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.wipro.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Wipro-logo.png"
                alt="Wipro-Logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Google_Cloud_Partner.png"
                alt="Google_Cloud_Partner_logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.aaae.org/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/AAAE-logo.png"
                alt="AAAE-Logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.protiviti.com/in-en" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/Protiviti-Logo.png"
                alt="Protiviti-Logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.theprocurementhive.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/The-procurement-hive-logo.png"
                alt="The-procurement-hive-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://lnwestgate.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/LNWestgate_logo.jpg"
                alt="LNWestgate_logo.jpg-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://ibexexperts.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/IBEX_Logo.png"
                alt="IBEX_Logo_white-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://impendi.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/impendi-logo.png"
                alt="Impendi-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.itss.co.za/index.html" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/ITSS-logo.png"
                alt="ITService-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.mpcconsulting.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/mpc-consulting-logo.png"
                alt="mpcconsulting-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.newdominionconsulting.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/NewDominion_Logo.png"
                alt="NewDominion-logo"
              />
            </a>
          </div>
          <div className="partner-Slide">
            <a href="https://www.costbits.com/" target="blank">
              <img
                className="partner-Slider-Img"
                src="https://storage.googleapis.com/raindroppublic/website_data/CostBits_logo.png"
                alt="costbits-logo"
              />
            </a>
          </div>
        </div>
      </div>
    </PartnersCarouselSliderWrapper>
  )
}
export default AppPartnersCarousalSlider
